import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadingIndicatorComponent } from '@components/shared';
import { ProspectForm } from '@components/shared/prospect-form/prospect-form.component';
import { IPartialAdvert } from '@components/types';
import { loadAdvertsFromStore, mapToTableData } from '@helpers/advert.helper';
import { AdvertViewModel } from '@models/advert/advert';
import { IProspect, ProspectPortal, ProspectStatus } from '@models/backend/prospects';
import { Selectable } from '@models/common/selectable';
import { ITeaserFilterOptions } from '@models/common/teaser-filter-options';
import { GeneralInquiryViewModel } from '@models/general-inquiry';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { ProspectViewModel } from '@models/prospect';
import { AdvertService } from '@services/advert.service';
import { AdvertStoreService } from '@services/advert.store';
import { AnalyticsService } from '@services/analytics.service';
import { GeneralInquiryService } from '@services/general-inquiries.service';
import { catchError, EMPTY, finalize, takeUntil, timer } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { GeneralInquiryCopyModalArgs } from './types';
export enum GeneralInquiryViewType {
    CreationConfirmation = 'creationConfirmation',
    CreatedTemplate = 'createdTemplate',
    CreationErrorTemplate = 'createdErrorTemplate',
    CreationWarningTemplate = 'createdWarningTemplate',
    CreationForbiddenTemplate = 'creationForbiddenTemplate',
}
@Component({
    selector: 'general-inquiry-copy',
    templateUrl: './general-inquiry-copy.component.html',
    styleUrls: ['./general-inquiry-copy.component.less'],
})
export class GeneralInquiryCopyComponent extends UnSubscriptionDirective implements OnInit {
    args = inject(MAT_DIALOG_DATA) as GeneralInquiryCopyModalArgs;
    dialogRef = inject<MatDialogRef<GeneralInquiryCopyComponent>>(MatDialogRef);
    private advertService = inject(AdvertService);
    private generalInquiryService = inject(GeneralInquiryService);
    private advertStoreService = inject(AdvertStoreService);
    private gaService = inject(AnalyticsService);
    private formBuilder = inject(FormBuilder);
    private cdr = inject(ChangeDetectorRef);

    @ViewChild(LoadingIndicatorComponent, { static: true })
    loadingIndicator: LoadingIndicatorComponent;

    currentView: GeneralInquiryViewType = GeneralInquiryViewType.CreationConfirmation;

    selectedFilterOptions: ITeaserFilterOptions = {
        streetName: null,
        searchQuery: null,
    };

    partialAdverts: Selectable<IPartialAdvert>[] = [];

    page = 1;
    isLast: boolean = false;

    generalInquiry: GeneralInquiryViewModel;
    advertId: string;

    selectedTemplate: TemplateRef<unknown>;

    firstFormGroup = this.formBuilder.group({
        firstCtrl: ['', Validators.required],
    });
    secondFormGroup = this.formBuilder.group({
        secondCtrl: [false, Validators.requiredTrue],
    });

    prospect: ProspectViewModel;

    generalInquiryId: string;

    async ngOnInit(): Promise<void> {
        this.generalInquiry = this.args.generalInquiry;
        this.prospect = this.prepareInitProspect();

        this.loadingIndicator.show();

        await loadAdvertsFromStore(
            this.advertService,
            this.advertStoreService.advertsSubject,
            this.unsubscribe$,
            (adverts) => {
                this.advertStoreService.updateAdverts(adverts);
                this.loadingIndicator.hide();
                this.partialAdverts = adverts.map((advert) => mapToTableData(advert));
            },
        );

        this.dialogRef
            .backdropClick()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.cancel());
    }

    filterChanged(selectedFilterOptions: ITeaserFilterOptions) {
        this.partialAdverts = [];
        this.isLast = false;
        this.page = 0;
        this.selectedFilterOptions = selectedFilterOptions;
        this.loadAdverts();
    }

    onScroll(): void {
        if (!this.isLast) {
            this.page = this.page + 1;
            this.loadAdverts();
        }
    }

    advertSelected(advertId: string): void {
        if (advertId) {
            this.advertId = advertId;
            this.firstFormGroup.get('firstCtrl')?.setValue(advertId);
            return;
        }

        this.advertId = '';
        this.firstFormGroup.get('firstCtrl')?.setValue('');
    }

    prospectChanged({ data, isValid }: ProspectForm): void {
        this.prospect = data;

        this.secondFormGroup.get('secondCtrl')?.setValue(isValid);
    }

    cancel(): void {
        this.dialogRef.close(this.generalInquiryId);
    }

    copy(): void {
        this.loadingIndicator.show();

        this.generalInquiryService
            .handleConvertGeneralInquiryToProspect(this.advertId, this.generalInquiry.id, this.prospect)
            .pipe(
                catchError((errors: HttpErrorResponse[]) => {
                    const { status } = errors[0];
                    switch (status) {
                        case 409:
                            this.currentView = GeneralInquiryViewType.CreationWarningTemplate;
                            break;
                        case 403:
                            this.currentView = GeneralInquiryViewType.CreationForbiddenTemplate;
                            break;
                        default:
                            this.currentView = GeneralInquiryViewType.CreationErrorTemplate;
                    }

                    this.loadingIndicator.hide();
                    return EMPTY;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => {
                this.generalInquiryId = this.generalInquiry.id;
                this.gaService.event(GoogleAnalyticsEvents.ProspectCreated);
                this.currentView = GeneralInquiryViewType.CreatedTemplate;
                this.loadingIndicator.hide();
            });
    }

    loadAdverts(): void {
        this.loadingIndicator.show();
        this.advertService
            .getAdvertTeasers(this.selectedFilterOptions, this.page)
            .pipe(
                finalize(() => {
                    this.loadingIndicator.hide();
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((response) => {
                const { data, isLast } = response;
                const advertsData = data.map((advert) => mapToTableData(AdvertViewModel.factory(advert)));
                this.partialAdverts.push(...advertsData);
                this.isLast = isLast;
            });
    }

    onStepChange(event: StepperSelectionEvent) {
        timer(300)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                if (event.selectedIndex === 0) {
                    this.resetForm(this.firstFormGroup, 'firstCtrl', this.advertId);
                } else if (event.selectedIndex === 1) {
                    this.resetForm(this.secondFormGroup, 'secondCtrl', true);
                }

                this.cdr.detectChanges();
            });
    }

    private resetForm(formGroup: FormGroup, ctrl: string, value: unknown): void {
        formGroup.get(`${ctrl}`)?.setValue(value);
        formGroup.markAllAsTouched();
        formGroup.updateValueAndValidity();
    }

    private prepareInitProspect(): ProspectViewModel {
        const prospect: IProspect = {
            id: null,
            marketingChannel: null,
            marketingOptIn: null,
            statusScore: null,
            advertId: null,
            countryCode: this.generalInquiry.countryCode,
            dateOfReceipt: this.generalInquiry.dateOfReceipt,
            email: this.generalInquiry.email,
            message: this.generalInquiry.message,
            name: this.generalInquiry.name,
            phone: this.generalInquiry.phone,
            portalName: ProspectPortal.CallCenter,
            status: this.generalInquiry.status as unknown as ProspectStatus,
        };

        const prospectViewModel = ProspectViewModel.factory(prospect);

        return prospectViewModel;
    }
}
