import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    computed,
    ElementRef,
    inject,
    OnInit,
    Renderer2,
    Signal,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadingIndicatorComponent } from '@components/shared';
import { CopyService } from '@components/shared/advert-copy/copy.service';
import { IPartialAdvert } from '@components/types';
import { loadAdvertsFromStore, mapToTableData } from '@helpers/advert.helper';
import { AdvertViewModel } from '@models/advert/advert';
import { IProspect } from '@models/backend/prospects';
import { BulkOperationResult, NoContentResponse } from '@models/backend/responses';
import { Selectable } from '@models/common/selectable';
import { ITeaserFilterOptions } from '@models/common/teaser-filter-options';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { ProspectViewModel } from '@models/prospect';
import { TranslateService } from '@ngx-translate/core';
import { AdvertService } from '@services/advert.service';
import { AdvertStoreService } from '@services/advert.store';
import { AnalyticsService } from '@services/analytics.service';
import { ProspectsService } from '@services/prospects.service';
import { catchError, EMPTY, finalize, takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { ProspectCopyModalArgs } from './types';

export type PropsectCopyViewTemplate =
    | 'prospectCreatedTemplate'
    | 'prospectCreationErrorTemplate'
    | 'prospectCreationWarningTemplate'
    | 'prospectCreationConfirmationTemplate'
    | 'prospectPartialyCreatedTemplate';

@Component({
    selector: 'prospect-copy',
    templateUrl: './prospect-copy.component.html',
    styleUrls: ['./prospect-copy.component.less'],
})
export class ProspectCopyComponent extends UnSubscriptionDirective implements OnInit {
    private copyService = inject(CopyService);
    private advertService = inject(AdvertService);
    private prospectsService = inject(ProspectsService);
    private advertStoreService = inject(AdvertStoreService);
    private element = inject(ElementRef);
    private renderer = inject(Renderer2);
    private gaService = inject(AnalyticsService);
    private translateService = inject(TranslateService);

    args = inject<ProspectCopyModalArgs>(MAT_DIALOG_DATA);
    dialogRef = inject<MatDialogRef<ProspectCopyComponent>>(MatDialogRef);

    @ViewChild(LoadingIndicatorComponent, { static: true })
    loadingIndicator: LoadingIndicatorComponent;

    @ViewChild('prospectCreatedTemplate') prospectCreatedTemplate: TemplateRef<unknown>;
    @ViewChild('prospectCreationErrorTemplate') prospectCreationErrorTemplate: TemplateRef<unknown>;
    @ViewChild('prospectCreationWarningTemplate') prospectCreationWarningTemplate: TemplateRef<unknown>;
    @ViewChild('prospectCreationConfirmationTemplate') prospectCreationConfirmationTemplate: TemplateRef<unknown>;
    @ViewChild('prospectPartialyCreatedTemplate') prospectPartialyCreatedTemplate: TemplateRef<unknown>;

    selectedFilterOptions: ITeaserFilterOptions = {
        streetName: null,
        searchQuery: null,
    };

    partialAdverts: Selectable<IPartialAdvert>[] = [];

    page = 1;
    isLast: boolean = false;

    showAdvertList: boolean = true;

    sourceAdvertId: string;
    targetAdvertId: string;
    prospectIds: string[];
    prospects: ProspectViewModel[];

    selectedTemplate: TemplateRef<unknown>;

    isConfirmButtonEnabled: Signal<boolean> = computed(() => !this.copyService.advertIds().length);

    failedProspects: IProspect[] = [];
    warningMessage = computed<string>(() => '');

    get isBulkOperation(): boolean {
        return this.prospects.length > 1;
    }

    async ngOnInit(): Promise<void> {
        this.loadingIndicator.show();

        await loadAdvertsFromStore(
            this.advertService,
            this.advertStoreService.advertsSubject,
            this.unsubscribe$,
            (adverts) => {
                this.advertStoreService.updateAdverts(adverts);
                this.loadingIndicator.hide();
                this.partialAdverts = adverts.map((advert) => mapToTableData(advert));
            },
        );

        this.sourceAdvertId = this.args.sourceAdvertId;
        this.prospectIds = this.args.prospects.map((prospect) => prospect.id);
        this.prospects = this.args.prospects;

        this.dialogRef.afterClosed().subscribe(() => {
            this.copyService.advertIds.set([]);
        });
    }

    onScroll(): void {
        if (!this.isLast) {
            this.page = this.page + 1;
            this.loadAdverts();
        }
    }

    filterChanged(selectedFilterOptions: ITeaserFilterOptions) {
        this.partialAdverts = [];
        this.isLast = false;
        this.page = 0;
        this.selectedFilterOptions = selectedFilterOptions;
        this.loadAdverts();
    }

    cancel(): void {
        this.dialogRef.close();
    }

    copy(): void {
        this.targetAdvertId = this.copyService.advertIds()[0];
        this.loadingIndicator.show();

        this.prospectsService
            .copyProspects(this.sourceAdvertId, this.targetAdvertId, this.prospectIds)
            .pipe(
                catchError((errors: HttpErrorResponse[]) => {
                    const errorStatus = errors[0].status;
                    if (errorStatus === 409 || errorStatus === 403) {
                        this.setWarningMessage(errorStatus);
                        this.selectTemplate('prospectCreationWarningTemplate');
                        this.loadingIndicator.hide();
                        return EMPTY;
                    }

                    this.selectTemplate('prospectCreationErrorTemplate');
                    this.loadingIndicator.hide();
                    return EMPTY;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((results) => {
                this.gaService.event(GoogleAnalyticsEvents.ProspectCreated);

                if (this.isBulkOperationResponse(results)) {
                    this.failedProspects = results
                        .filter((result) => result.status === 'failure')
                        .map((result) => result.value);

                    this.selectTemplate('prospectPartialyCreatedTemplate');
                    this.loadingIndicator.hide();
                    return;
                }

                this.selectTemplate('prospectCreatedTemplate');
                this.loadingIndicator.hide();
            });
    }

    confirm(): void {
        this.showAdvertList = false;
        this.selectTemplate('prospectCreationConfirmationTemplate');
    }

    loadAdverts(): void {
        this.loadingIndicator.show();
        this.advertService
            .getAdvertTeasers(this.selectedFilterOptions, this.page)
            .pipe(
                finalize(() => {
                    this.loadingIndicator.hide();
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((response) => {
                const { data, isLast } = response;
                const advertsData = data.map((advert) => mapToTableData(AdvertViewModel.factory(advert)));
                this.partialAdverts.push(...advertsData);
                this.isLast = isLast;
            });
    }

    backToAdvertList(): void {
        this.showAdvertList = true;
        this.selectTemplate(null);
    }

    private setWarningMessage(errorStatus: number): void {
        this.warningMessage =
            errorStatus === 403
                ? computed<string>(() =>
                      this.getMessage(
                          'SHARED_COMPONENT.PROSPECT_COPY.PROSPECTS_WARNING_FORBIDDEN',
                          'SHARED_COMPONENT.PROSPECT_COPY.WARNING_FORBIDDEN',
                      ),
                  )
                : computed<string>(() =>
                      this.getMessage(
                          'SHARED_COMPONENT.PROSPECT_COPY.PROSPECTS_WARNING',
                          'SHARED_COMPONENT.PROSPECT_COPY.WARNING',
                      ),
                  );
    }

    getMessage(bulkKey: string, singleKey: string): string {
        return this.isBulkOperation ? this.translateService.instant(bulkKey) : this.translateService.instant(singleKey);
    }

    byAdvertId(_index: number, advert: IPartialAdvert): string {
        return advert.advertId;
    }

    private selectTemplate(template: PropsectCopyViewTemplate): void {
        this.renderer.setStyle(this.element.nativeElement, 'min-height', 'auto');
        this.selectedTemplate = this[template];
    }

    private isBulkOperationResponse(
        result: NoContentResponse | BulkOperationResult<IProspect>[],
    ): result is BulkOperationResult<IProspect>[] {
        return result !== null && (result as BulkOperationResult<IProspect>[]).length > 0;
    }
}
